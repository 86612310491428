import React, { useState } from "react"
import styled from "styled-components"

import NavbarHeader from "./NavbarHeader"
import NavbarLinks from "./NavbarLinks"
import NavbarIcons from "./NavbarIcons"


const Navbar = ({ siteMetadata }) => {
  const [stateNavbarOpen, setStateNavbarOpen] = useState(false);

  function handleNavbar() {
    setStateNavbarOpen(!stateNavbarOpen)
  };

  //tutorial way below, setState returning function, rather than new state oibj?
  // handleNavbar = () => {
  //   this.setState(() => {
  //     return { navbarOpen: !this.state.navbarOpen }
  //   })
  // }
  return (
    <NavWrapper>
      <NavbarHeader handleNavbar={handleNavbar} title={siteMetadata.title} />
      <NavbarLinks navbarOpen={stateNavbarOpen} />
      <NavbarIcons siteMetadata={siteMetadata} />
    </NavWrapper>
  )
}

// Media queries are slightly different in styled components
const NavWrapper = styled.nav`
  position: fixed;
  width: 100%;
  z-index: 99;
  background: white;

  @media (min-width: 769px) {
    display: flex;
    align-items: center;
  }
`;

export default Navbar;
