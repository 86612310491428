import React, { useState } from "react"
import { Link } from "gatsby"

import PropTypes from "prop-types"
import "./layout.css";

import Navbar from "./globals/navbar/Navbar";


const Header = ({ siteMetadata }) => {
  return (
    <Navbar siteMetadata={siteMetadata} />
  );
};


Header.propTypes = {
  siteMetadata: PropTypes.objectOf(PropTypes.any),
}

Header.defaultProps = {
  siteMetadata: {},
}

export default Header
