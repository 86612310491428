import React from "react";
import { objectOf, any } from "prop-types";
import { Link } from "gatsby";
import { FaInstagram, FaWhatsapp, FaFacebook } from "react-icons/fa";

// import Image from "./image";
import { defaultLinks } from "../../lib/constants";

// TODO: change all the footer content
const Footer = ({ siteMetadata }) => (
  <>
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
            <Link className="logo-footer" to="/">{siteMetadata.title}<span className="text-primary">.</span></Link>
            <p className="mt-4">Babi Panggang Karo Pasti Top!</p>
            <div>{siteMetadata.address}</div>
            <div>{siteMetadata.phone}</div>
            <div>Jam Operasional: 10:00 - 21:00 Setiap Hari</div>
            <ul className="list-unstyled social-icon social mb-0 mt-4">
              <li className="list-inline-item mr-3">
                <a target="_blank" rel="noopener noreferrer" href="https://wa.me/6285264481801?text=Halo+bang%2C+mau+pesan+nih">
                  <FaWhatsapp className="icon whatsapp-icon" /> <span className="text-light font-weight-bold">+62 852-4319-4649</span>
                </a>
              </li>
              <li className="list-inline-item mr-3">
                <a target="_blank" rel="noopener noreferrer" href={siteMetadata.instagram.link}>
                  <FaInstagram className="icon instagram-icon" /> <span className="text-light font-weight-bold">{siteMetadata.instagram.label}</span>
                </a>
              </li>
              <li className="list-inline-item mr-3">
                <a target="_blank" rel="noopener noreferrer" href={siteMetadata.facebook.link}>
                  <FaFacebook className="icon facebook-icon" /> <span className="text-light font-weight-bold">{siteMetadata.facebook.label}</span>
                </a>
              </li>
            </ul>
          </div>
          
          <div className="col-lg-3 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h4 className="text-light footer-head">Restaurant</h4>
            <ul className="list-unstyled footer-list mt-4">
              {defaultLinks.map(link => (
                <li key={link.id}>
                  <Link to={link.path} className="text-foot" {...(link.path.includes('http:') || link.path.includes('https:') && { target: "_blank", rel: "noopener noreferrer" })}>
                    <i className="mdi mdi-chevron-right mr-1" /> {link.name}
                  </Link>
                </li>
              ))}
              {/* <li><Link to="#" className="text-foot"><i className="mdi mdi-chevron-right mr-1" /> Team</Link></li> */}
              {/* <li><Link to="#" className="text-foot"><i className="mdi mdi-chevron-right mr-1" /> Pricing</Link></li>
              <li><Link to="#" className="text-foot"><i className="mdi mdi-chevron-right mr-1" /> Features</Link></li>
              <li><Link to="#" className="text-foot"><i className="mdi mdi-chevron-right mr-1" /> FAQ</Link></li>
              <li><Link to="#" className="text-foot"><i className="mdi mdi-chevron-right mr-1" /> Blog</Link></li>
              <li><Link to="#" className="text-foot"><i className="mdi mdi-chevron-right mr-1" /> Login</Link></li> */}
            </ul>
          </div>
          
          <div className="col-lg-3 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h4 className="text-light footer-head">Link Terkait</h4>
            <ul className="list-unstyled footer-list mt-4">{}
              {/* <li><Link to="#" className="text-foot"><i className="mdi mdi-chevron-right mr-1" /> Syarat dan Ketentuan </Link></li> */}
              <li><a target="_blank" rel="noopener noreferrer" href={siteMetadata.grabfoodLink} className="text-foot"><i className="mdi mdi-chevron-right mr-1" /> Pesan Melalui Grabfood </a></li>
              <li><a target="_blank" rel="noopener noreferrer" href={siteMetadata.gofoodLink} className="text-foot"><i className="mdi mdi-chevron-right mr-1" /> Pesan Melalui Gofood </a></li>
              <li><a target="_blank" rel="noopener noreferrer" href={siteMetadata.gostoreLink} className="text-foot"><i className="mdi mdi-chevron-right mr-1" /> Pesan Online melalui Gostore </a></li>
              {/* <li><Link to="#" className="text-foot"><i className="mdi mdi-chevron-right mr-1" /> Safe Payments</Link></li>
              <li><Link to="#" className="text-foot"><i className="mdi mdi-chevron-right mr-1" /> Terms of Services</Link></li>
              <li><Link to="#" className="text-foot"><i className="mdi mdi-chevron-right mr-1" /> Documentation</Link></li>
              <li><Link to="#" className="text-foot"><i className="mdi mdi-chevron-right mr-1" /> Privacy Policy</Link></li> */}
            </ul>
          </div>
          {/* <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h4 className="text-light footer-head">Newsletter</h4>
            <p className="mt-4">Sign up and receive the latest tips via email.</p>
            <form>
              <div className="row">
                <div className="col-lg-12">
                  <div className="foot-subscribe form-group position-relative">
                    <label>Write your email <span className="text-danger">*</span></label>
                    <i className="mdi mdi-email ml-3 icons" />
                    <input type="email" name="email" id="emailsubscribe" className="form-control pl-5 rounded" placeholder="Your email : " required />
                  </div>
                </div>
                <div className="col-lg-12">
                  <input type="submit" id="submitsubscribe" name="send" className="btn btn-primary w-100" value="Subscribe" />
                </div>
              </div>
            </form>
          </div> */}
        </div>
      </div>
    </footer>
    <hr />
    <footer className="footer footer-bar">
      <div className="container text-center">
        <div className="row align-items-center">
          <div className="col-sm-6">
            <div className="text-sm-left">
            <p className="mb-0">
              © {new Date().getFullYear()} BPK Lopiga. Created with ❤️ by 
              <a alt="Gadani" target="_blank" rel="noopener noreferrer" href="mailto:gadanihiman@gmail.com"> Gadani H.G </a>
            </p>
            </div>
          </div>
          <div className="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <ul className="list-unstyled payment-cards text-sm-right mb-0">
              <li className="list-inline-item mr-3">
                <a target="_blank" rel="noopener noreferrer" href="https://wa.me/6285264481801?text=Halo+bang%2C+mau+pesan+nih">
                  <FaWhatsapp className="icon whatsapp-icon" /> <span className="text-light font-weight-bold">+62 852-4319-4649</span>
                </a>
              </li>
              <li className="list-inline-item mr-3">
                <a target="_blank" rel="noopener noreferrer" href={siteMetadata.instagram.link}>
                  <FaInstagram className="icon instagram-icon" /> <span className="text-light font-weight-bold">{siteMetadata.instagram.label}</span>
                </a>
              </li>
              <li className="list-inline-item mr-3">
                <a target="_blank" rel="noopener noreferrer" href={siteMetadata.facebook.link}>
                  <FaFacebook className="icon facebook-icon" /> <span className="text-light font-weight-bold">{siteMetadata.facebook.label}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </>
);

Footer.propTypes = {
  siteMetadata: objectOf(any).isRequired,
};

export default Footer;
