import React from "react"

import { Link } from "gatsby"
import styled from "styled-components"
import { FaAlignRight } from "react-icons/fa"
import logo from "../../../../images/icon.jpg"


import { styles } from "../../../../lib/utils";

const NavbarHeader = ({ title, handleNavbar }) => {
  return (
      <HeaderWrapper>
        <Link to="/">
          <img src={logo} width={40} height={40} alt="BPK Lopiga Bremaha Logo" />
        </Link>
        {title && <Link to="/" className="nav-link">{title}</Link>}
        <FaAlignRight
          className="toggle-icon"
          onClick={handleNavbar}
        />
      </HeaderWrapper>
  )
}

export default NavbarHeader;

const HeaderWrapper = styled.div`
  padding: 0.4rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .toggle-icon {
    font-size: 1.75rem;
    color: ${styles.colors.mainYellow};
    cursor: pointer;
  }

  .nav-link {
    display: block;
    text-decoration: none;
    color: ${styles.colors.mainGrey};
    font-weight: 700;
    text-transform: capitalize;
    cursor: pointer;
    ${styles.transDefault};
  }

  @media (min-width: 768px) {
    .toggle-icon {
      display: none;
    }
  }
`
