import styled from "styled-components"

// eslint-disable-next-line import/prefer-default-export
export const Section = styled.section`
  @media (min-width: 768px) {
    width: ${({widthDesktop}) => widthDesktop ? widthDesktop : '65vw'};
  }
  padding: 2rem 0;
  margin: 0 auto;
  width: ${({widthMobile}) => widthMobile ? widthMobile : '90vw'};
`
