import React from "react"
import { any, node } from "prop-types"
import styled from "styled-components"

import BannerImage from "../../images/BannerBPK3.jpg"

export function HomeHeader({ image, children }) {
  return <IndexHeader image={image}>{children}</IndexHeader>
}

export function PageHeader({ image, children }) {
  return <DefaultHeader image={image}>{children}</DefaultHeader>
}

const IndexHeader = styled.header`
  @media (min-width: 768px) {
    min-height: calc(100vh - 427px);
    background-position: 0px -320px !important;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url(${props => props.image}) center/cover fixed no-repeat;
  }
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${props => props.image}) fixed no-repeat;
  background-size: cover;
  min-height: calc(140vh - 427px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

// this component inherits all of the styles from the styled component passed in, other than the changed contained
const DefaultHeader = styled(IndexHeader)`
  min-height: 60vh;
`;

HomeHeader.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  image: any,
  children: node.isRequired,
}

// setting up default prop, in case image (or something else) is not passed in
HomeHeader.defaultProps = {
  image: BannerImage,
}

PageHeader.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  image: any,
  children: node.isRequired,
}

// setting up default prop, in case image (or something else) is not passed in
PageHeader.defaultProps = {
  image: BannerImage,
}

DefaultHeader.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  image: any,
}

DefaultHeader.defaultProps = {
  image: BannerImage,
}
