import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import { styles } from "../../../../lib/utils";
import { defaultLinks } from "../../../../lib/constants";

const NavbarLinks = ({ navbarOpen }) => {
  const [stateLinks, setStateLinks] = useState(defaultLinks);
  return (
    <LinkWrapper open={navbarOpen}>
      {stateLinks.map(link => {
        return (
          <li key={link.id}>
            <Link to={link.path} className={`nav-link ${link.highlight && 'text-success'}`} {...(link.path.includes('http:') || link.path.includes('https:') && { target: "_blank", rel: "noopener noreferrer" })}>
              {link.name}
            </Link>
          </li>
        )
      })}
    </LinkWrapper>
  )
}

const LinkWrapper = styled.ul`
  li {
    list-style-type: none;
  }

  .nav-link {
    display: block;
    text-decoration: none;
    padding: 0.5rem 1rem 0.5rem 1rem;
    color: ${styles.colors.mainGrey};
    font-weight: 700;
    text-transform: capitalize;
    cursor: pointer;
    ${styles.transDefault};

    &:hover {
      background: ${styles.colors.mainGrey};
      color: ${styles.colors.mainYellow};
      padding: 0.5rem 1rem 0.5rem 1.3rem;
    }
  }
  height: ${props => (props.open ? "152px" : 0)};
  overflow: hidden;
  ${styles.transObject({})};
  padding-right: 92px;
  @media (min-width: 768px) {
    height: auto;
    display: flex;
    margin: 0 auto;
    .nav-link:hover {
      background: ${styles.colors.mainWhite};
      padding: 0.5rem 1rem;
    }
  }
`;

export default NavbarLinks;
