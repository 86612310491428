import React, { useState } from "react";
import styled from "styled-components";
import { any, objectOf } from "prop-types";
import { FaInstagram, FaFacebook } from "react-icons/fa";
import { styles } from "../../../../lib/utils";

const NavbarIcons = ({ siteMetadata }) => {
  const defaultIcons = [
    {
      id: 1,
      icon: <FaFacebook className="icon facebook-icon" />,
      path: siteMetadata.facebook.link,
    },
    {
      id: 2,
      icon: <FaInstagram className="icon instagram-icon" />,
      path: siteMetadata.instagram.link,
    },
  ];
  const [stateIcons] = useState(defaultIcons);

  return (
    <IconWrapper>
      {stateIcons.map(i => {
        return (
          <a
            href={i.path}
            key={i.id}
            target="_blank"
            rel="noopener noreferrer"
          >
            {i.icon}
          </a>
        )
      })}
    </IconWrapper>
  )
};

const IconWrapper = styled.div`
  .icon {
    font-size: 1.3rem;
    cursor: pointer;
    ${styles.transFunction()};
  }
  .facebook-icon {
    color: #3b579d;
  }
  .instagram-icon {
    color: #da5f53;
  }
  .icon:hover {
    color: ${styles.colors.mainYellow};
  }
  display: none;
  @media (min-width: 768px) {
    margin-right: 20px;
    display: flex;
    width: 5rem;
    justify-content: space-around;
  }
`;

NavbarIcons.propTypes = {
  siteMetadata: objectOf(any)
};

NavbarIcons.defaultProps = {
  siteMetadata: {
    facebook: {},
    instagram: {},
  },
};

export default NavbarIcons;
