/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Header from "./header";
import Footer from "./footer";
import "./bootstrap.min.css"
import "./layout.css";

// const { Content } = Container;

const Layout = ({ children, data }) => {
  return (
    <>
      <Header siteMetadata={data.site.siteMetadata} />
      {/* <Content style={{ padding: '0 50px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
          <Breadcrumb.Item>App</Breadcrumb.Item>
        </Breadcrumb> */}
        <div className="site-layout-content">{children}</div>
      {/* </Content> */}
      <Footer siteMetadata={data.site.siteMetadata} style={{ textAlign: 'center' }} />
    </>
  )
}

Layout.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  children: PropTypes.node.isRequired,
}

export default Layout
